/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-08-16",
    versionChannel: "nightly",
    buildDate: "2024-08-16T00:05:27.741Z",
    commitHash: "dda4b571090a17767de74a042e01413c1a768e12",
};
